// Copyright © 2017 Moxley Data Systems - All Rights Reserved

import { buildClassName } from "lib/string";
import React, { LegacyRef } from "react";

interface Props {
  additionalAccepts?: string[];
  onChange?: (file: File) => void;
  className?: string;
  inputRef?: LegacyRef<HTMLInputElement>;
  name?: string;
}

export default function FileInput(props: Props) {
  const { additionalAccepts, className, name } = props;
  const inputRef = props.inputRef || React.createRef();

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { target } = event;
    if (!target || !target.files) {
      console.log("No target files");
      return null;
    }
    const file = target.files.length > 0 ? target.files[0] : null;
    if (!file) {
      console.log("No file");
      return null;
    }
    event.preventDefault();
    if (file) {
      const { onChange } = props;
      onChange && onChange(file);
    }
  }

  const accept = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/svg+xml",
    ...(additionalAccepts || []),
  ];

  return (
    <input
      type="file"
      ref={inputRef}
      name={name}
      onChange={onChange}
      accept={accept.join(",")}
      className={buildClassName("FileInput", className)}
    />
  );
}
